//@ts-check
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import productReducer from "./productReducer";
import notificationReducer from "./notificationReducer";
import userReducer from "./userReducer";
import loaderReducer from "./loaderReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  products: productReducer,
  notifications: notificationReducer,
  loader: loaderReducer,
  users: userReducer,
});

export default rootReducer;
