import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { withRouter } from "react-router";

import countryList from "../../config/country";
import { verifyEmailAction } from "./../../store/actions/auth.action";

const VerifyEmail = (props) => {
  let [state, setState] = useState({
    confirmPassword: "",
    password: "",
    countryCode: "",
    mobile: "",
  });

  const token = props.match.params.token;
  const { errors, handleSubmit, register, control, reset } = useForm({
    defaultValues: {
      confirmPassword: "",
      password: "",
      countryCode: "",
      mobile: "",
    },
  });

  useEffect(() => {
    reset();
  }, []);

  const countryListWithCode = useMemo(
    () =>
      countryList.sort(
        (a, b) =>
          parseInt(a.dial_code?.replace("+", "")) -
          parseInt(b.dial_code?.replace("+", ""))
      ),
    [countryList]
  );

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSumbit = (data) => {
    if (state.password === state.confirmPassword) {
      props.verifyEmailActionProp(data, token);
      setState({
        confirmPassword: "",
        password: "",
        countryCode: "",
        mobile: "",
      });
      props.history.push("/verify-otp");
    }
  };

  if (props.user) {
    return (
      <div className="dashboard container">
        <div className="card z-depth-0">
          <div className="card-content">
            <div className="row">
              <div className="col s12">
                <span className="card-title">
                  Please Logout from existing user
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard container">
      <div className="card z-depth-0">
        <div className="card-content">
          <div className="row">
            <div className="col s12">
              <span className="card-title">Set Password</span>
            </div>
          </div>

          <div className="row">
            <div className="col s6">
              <form
                className="login-form"
                onSubmit={handleSubmit(handleSumbit)}
                autoComplete="off"
              >
                <div className="input-field">
                  <label htmlFor="name"> Password </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                    ref={register({
                      required: "Please enter the password",
                    })}
                  />
                  {errors.password && (
                    <p className="red-text"> Please enter the password </p>
                  )}
                </div>
                <div className="input-field">
                  <label htmlFor="email"> Confirm Password </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={state.confirmPassword}
                    onChange={handleChange}
                    ref={register({
                      required: "Please confirm password",
                    })}
                  />
                  {errors.confirmPassword && (
                    <p className="red-text"> Please confirm password </p>
                  )}
                </div>

                <div>
                  <div class="input-field">
                    <div>
                      <label htmlFor="role"> Country Code </label>
                    </div>
                    <Controller
                      control={control}
                      name="countryCode"
                      rules={{ required: true }}
                      render={({ onChange, value }) => (
                        <select
                          className="browser-default"
                          onChange={onChange}
                          rules={{ required: true }}
                          name="countryCode"
                          value={value}
                        >
                          <option selected="selected">
                            Please select country
                          </option>
                          {countryListWithCode.map((c) => (
                            <option
                              value={c.dial_code}
                            >{`+${c.dial_code} (${c.name})`}</option>
                          ))}
                          {/* <option value="92"> +92 </option>
                          <option value="93"> +93 </option> */}
                        </select>
                      )}
                    />
                    {errors.countryCode && (
                      <p className="red-text">Please select the Country Code</p>
                    )}
                  </div>

                  <div className="input-field">
                    <label htmlFor="email"> Mobile Number </label>
                    <input
                      type="number"
                      id="mobile"
                      name="mobile"
                      value={state.mobile}
                      onChange={handleChange}
                      ref={register({
                        required: "Please confirm password",
                      })}
                    />
                    {errors.mobile && (
                      <p className="red-text"> Please confirm password </p>
                    )}
                  </div>
                </div>
                <div className="input-field">
                  <button
                    className="btn z-depth-0"
                    type="submit"
                    disabled={!!Object.keys(errors).length}
                  >
                    Submit
                  </button>
                </div>

                <div className="red-text">
                  {state.password &&
                  state.confirmPassword &&
                  state.password !== state.confirmPassword ? (
                    <p>Password does mot match</p>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyEmailActionProp: (newUser, token) =>
      verifyEmailAction(dispatch, newUser, token),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
);
