import handleRequest from "../../services/http.service";
import actionTypes from "./actions-types";
import { NOTIFICATION, PRODUCT } from "./actions-types";
import { async } from "q";

export const fetchRfIdList = async (dispatch, newUser) => {
  let url = "/product/fetch-unlinked-rfid";
  let resp = await handleRequest({ method: "GET", url, data: newUser });
  try {
    if (resp.success) {
      return resp.data;
    } else {
      dispatch({
        type: NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: true, msg: resp.message },
      });
      if (resp.message === "Invalid Authentication Token") {
        dispatch({ type: "LOGOUT" });
      }
    }
  } catch (e) {
    let msg = e.response.data.message || "Something went wrong";
    dispatch({
      type: NOTIFICATION.SHOW_NOTFICATION,
      notification: { isError: true, msg: resp.message },
    });
  }
};

export const fetchUnLinkedProducts = async (dispatch) => {
  try {
    let url = "/product/fetch-unlinked-products";
    let resp = await handleRequest({ method: "GET_WITH_AUTH", url });
    if (resp.success) {
      return resp.data;
    } else {
      dispatch({
        type: NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: true, msg: resp.message },
      });
      if (resp.message === "Invalid Authentication Token") {
        dispatch({ type: "LOGOUT" });
      }
    }
  } catch (e) {
    let msg = e.message || "Something went wrong";
    dispatch({
      type: NOTIFICATION.SHOW_NOTFICATION,
      notification: { isError: true, msg },
    });
  }
};

export const linkProduct = async (dispatch, data) => {
  try {
    let url = "/product/link-product";
    let token = localStorage.getItem("token");
    let config = { headers: { Authorization: "Bearer " + token } };
    let resp = await handleRequest({ method: "POST", url, data, config });
    if (resp.success) {
      dispatch({
        type: NOTIFICATION.SHOW_NOTFICATION,
        notification: { msg: "Product linked successfully" },
      });
      return true;
    } else {
      dispatch({
        type: NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: true, msg: resp.message },
      });
      if (resp.message === "Invalid Authentication Token") {
        dispatch({ type: "LOGOUT" });
      }
    }
  } catch (e) {
    let msg =
      (e.response && e.response.data && e.response.data.message) ||
      "Something went wrong";
    dispatch({
      type: NOTIFICATION.SHOW_NOTFICATION,
      notification: { isError: true, msg },
    });
  }
};
