import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import html2canvas from 'html2canvas'
import { QR_IMAGE_PATHS } from '../../config/config'

const ProductsQrCode = ({ modal, toggle, setModal, product }) => {
    /**
     * Download QR Code
     */
    const resizeCanvas = (canvas, width, height) => {
        const resizedCanvas = document.createElement('canvas')
        const resizedContext = resizedCanvas.getContext('2d')
        resizedCanvas.width = width
        resizedCanvas.height = height
        resizedContext.drawImage(canvas, 0, 0, width, height)
        return resizedCanvas
    }

    const downloadQRCode = () => {
        const inputElement = document.getElementById('canvasBox')
        const downloadWidth = 18.89 // 0.5cm width of the downloaded image
        const downloadHeight = 18.89 // 0.5cm height of the downloaded image
        html2canvas(inputElement, { useCORS: true })
            .then((canvas) => {
                const resizedCanvas = resizeCanvas(canvas, downloadWidth, downloadHeight)
                const imageData = resizedCanvas.toDataURL('image/jpeg')
                const link = document.createElement('a')
                link.href = imageData
                link.download = 'ProductQR.jpg'
                link.click()
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    return (
        <Modal isOpen={modal} toggle={toggle} className='qr-code-modal' style={{ position: 'fixed', top: '20%', zIndex: '100', left: '50%', transform: 'translateX(-50%)', minWidth: '400px' }} size='lg'>
            <ModalBody>
                <div className='qr-code-main'>
                    <img src={`${QR_IMAGE_PATHS}/${product.productUId}.png`} style={{ width: '300px', height: '300px' }} alt='qr' id='canvasBox' />
                    <div>
                        <button style={{ marginRight: '10px' }} onClick={() => downloadQRCode()} className='btn waves-effect waves-light'>
                            Download
                        </button>
                        <button className='btn waves-effect waves-light' onClick={toggle}>
                            Close
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ProductsQrCode
