import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { addUserAction } from './../../store/actions/auth.action'

/**
 * This component will use state
 */
const AddUser = (props) => {
    let [state, setState] = useState({
        name: '',
        email: '',
        role: '',
    })

    const { errors, handleSubmit, register, control, reset } = useForm({
        defaultValues: {
            name: '',
            email: '',
            role: '',
        },
    })

    useEffect(() => {
        reset()
    }, [])

    let [err, setErr] = useState('')
    let [successMsg, setSuccessMsg] = useState('')

    function handleChange(e) {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    async function handleSumbit(data) {
        props.addUserActionProp(data)
        setState({ name: '', email: '', role: '' })
    }

    const handleSelect = (e) => {
        // let role = e.target.value;
        setState({ ...state, role: e.target.value })
    }

    return (
        <div className='dashboard container'>
            <div className='card z-depth-0'>
                <div className='card-content'>
                    <div className='row'>
                        <div className='col s12'>
                            <span className='card-title'>Add User</span>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col s6'>
                            <form className='login-form' onSubmit={handleSubmit(handleSumbit)} autoComplete='off'>
                                <div className='input-field'>
                                    <label htmlFor='name'> Name </label>
                                    <input
                                        type='text'
                                        id='name'
                                        name='name'
                                        value={state.name}
                                        onChange={handleChange}
                                        ref={register({
                                            required: 'Please enter the Name',
                                        })}
                                    />
                                    {errors.name && <p className='red-text'> Please enter the Name </p>}
                                </div>
                                <div className='input-field'>
                                    <label htmlFor='email'> Email </label>
                                    <input
                                        type='email'
                                        id='email'
                                        name='email'
                                        value={state.email}
                                        onChange={handleChange}
                                        ref={register({
                                            required: 'Please enter the Email',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Enter a valid e-mail address',
                                            },
                                        })}
                                    />
                                    {errors.email && <p className='red-text'> Please enter the email </p>}
                                </div>
                                <div class='input-field'>
                                    <div>
                                        <label htmlFor='role'> Role </label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name='role'
                                        rules={{ required: true }}
                                        render={({ onChange, value }) => (
                                            <select className='browser-default' onChange={onChange} rules={{ required: true }} name='role' value={value}>
                                                <option selected='selected'>Please select the role</option>
                                                <option value='admin'> Admin </option>
                                                <option value='simpleRead'> RFID-Simple-Read </option>
                                                <option value='fullRead'> RFID-Full-Read </option>
                                                <option value='fullRead2FA'>RFID-Full-Read-2FA</option>
                                                <option value='seller'>Seller</option>
                                            </select>
                                        )}
                                    />

                                    {/* <select
                    className="browser-default"
                    onChange={handleSelect}
                    rules={{ required: true }}
                    name="role"
                  >
                    <option selected="selected">Choose the file type</option>
                    <option value="admin"> Admin </option>
                    <option value="simpleRead"> RFID-Simple-Read </option>
                    <option value="fullRead"> RFID-Full-Read </option>
                    <option value="fullRead2FA"> RFID-Full-Read-2FA </option>
                  </select> */}

                                    {errors.role && <p className='red-text'> Please select the Role </p>}
                                </div>
                                <div className='input-field'>
                                    <button
                                        // type="button"
                                        className='btn z-depth-0'
                                        // onClick={handleSumbit}
                                        type='submit'
                                        disabled={!!Object.keys(errors).length}
                                    >
                                        {' '}
                                        Submit{' '}
                                    </button>
                                </div>
                                {/* <div className="red-text center">
                  {errors ? <p>"Please Fill all the fields"</p> : null}
                </div> */}
                                <div className='green-text center'>{successMsg ? <p>{successMsg}</p> : null}</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addUserActionProp: (newUser) => addUserAction(dispatch, newUser),
    }
}

export default connect(null, mapDispatchToProps)(AddUser)
