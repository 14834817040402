import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Modal from "react-modal";

import handleRequest from "./../../services/http.service";
import VerifyOTP from "./verifyOTP";
import { NOTIFICATION } from "../../store/actions/actions-types";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "50px",
    transform: "translate(-50%, -50%)",
  },
};

const Login = (props) => {
  let [creds, setCreds] = useState({ email: "", password: "" });
  let [message, setMessage] = useState({ text: "", type: "" });
  const [enableResendOTP, setEnableResendOTP] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const dispatch = useDispatch();

  if (props.user) return <Redirect to="/" />;

  async function handleSubmit(e) {
    e.preventDefault();

    if (!creds.email || !creds.password) {
      return;
    }

    let url = "/login";
    let resp = await handleRequest({ method: "POST", url, data: creds });
    if (!resp || !resp.success) {
      setMessage({
        text: (resp && resp.message) || "Something went wrong",
        type: "Error",
      });
      return;
    }

    if (resp && resp.success && resp.data && !resp.data.user.isMobileVerified) {
      localStorage.setItem("token", resp.data.token);

      setMessage({
        text: "You did not verify mobile yet, should we resend OTP again?",
        type: "Error",
      });
      setEnableResendOTP(true);
      return;
    }

    // dispatch an action to update user in redux store
    localStorage.setItem("token", resp.data.token);
    localStorage.setItem("user", JSON.stringify(resp.data.user));
    props.setUser(resp.data.user);

    // redirect to dashboard
  }

  function handleChange(e) {
    setCreds({ ...creds, [e.target.name]: e.target.value });
  }

  const getMessageClass = () => {
    let defaultClass = "center";
    if (message.type === "Error") {
      return defaultClass + " red-text";
    } else {
      return defaultClass + "green-text";
    }
  };

  const onResendOTPClick = async (e) => {
    e.preventDefault();
    setShowModal(true);

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    };
    const resp = await handleRequest({
      method: "POST",
      url: "/resend-otp",
      data: {},
      config,
    });
    if (!resp || !resp.success) {
      if (Object.keys(resp.message).length === 0) {
        setMessage({
          text: "Something went wrong",
          type: "Error",
        });
        return;
      } else {
        setMessage({
          text: (resp && resp.message) || "Something went wrong",
          type: "Error",
        });
        return;
      }
    }
  };

  const handleSubmitResetPassword = async (e) => {
    e.preventDefault();
    const resp = await handleRequest({
      method: "POST",
      url: "/send-reset-password",
      data: { email: resetEmail },
    });
    if (!resp || !resp.success) {
      if (Object.keys(resp.message).length === 0) {
        dispatch({
          type: NOTIFICATION.SHOW_NOTFICATION,
          notification: { isError: true, msg: resp.message },
        });
        setShowResetPasswordModal(false);
        setResetEmail("");
        return;
      } else {
        dispatch({
          type: NOTIFICATION.SHOW_NOTFICATION,
          notification: { isError: true, msg: "Something went wrong" },
        });
        setShowResetPasswordModal(false);
        setResetEmail("");
        return;
      }
    } else {
      dispatch({
        type: NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: false, msg: resp.message },
      });
      setShowResetPasswordModal(false);
      setResetEmail("");
    }
  };

  return (
    <div className="dashboard container">
      <div className="card z-depth-0">
        <div className="card-content">
          <div className="row">
            <div className="col s12">
              <span className="card-title">Sign In </span>
            </div>
          </div>

          <div className="row">
            <div className="col s6">
              <form onSubmit={handleSubmit} className="login-form">
                <div className="input-filed">
                  <label htmlFor="email"> Email </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={creds.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-filed">
                  <label htmlFor="password"> Password </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={creds.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-filed">
                  <button className="btn z-depth-0"> Login </button>
                  {enableResendOTP && (
                    <button
                      className="btn z-depth-0"
                      onClick={onResendOTPClick}
                    >
                      {" "}
                      Resend OTP{" "}
                    </button>
                  )}
                </div>
                <div
                  className={`center ${
                    message.type === "Error" ? "red-text" : "green-text"
                  }`}
                  style={{ fontWeight: "bold" }}
                >
                  {message ? <p>{message.text}</p> : null}
                </div>
              </form>
            </div>
          </div>

          <div className="row" style={{ marginTop: "10px", cursor: "pointer" }}>
            <div className="col s12">
              <span onClick={() => setShowResetPasswordModal(true)}>
                Forgot Password?{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={customStyles}
      >
        <VerifyOTP />
      </Modal>

      <Modal
        isOpen={showResetPasswordModal}
        onRequestClose={() => setShowResetPasswordModal(false)}
        style={customStyles}
      >
        <div>
          <form onSubmit={handleSubmitResetPassword} className="login-form">
            <div className="input-filed">
              <label htmlFor="email"> Email </label>
              <input
                type="email"
                id="email"
                name="email"
                value={resetEmail}
                onChange={(event) => setResetEmail(event.target.value)}
              />
            </div>
            <div>
              <button className="btn z-depth-0"> Reset password </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch({ type: "SET_USER", user });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
