import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import { verifyOTPAction } from "./../../store/actions/auth.action";

const VerifyOTP = (props) => {
  const [OTP, setOTP] = useState(null);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setOTP(e.target.value);
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    if (!OTP) {
      setError("Please Enter OTP");
    }
    if (OTP < 999 || OTP > 10000) {
      setError("OTP Must be of Four numbers");
    } else {
      props.verifyOTPActionProp({ otp: parseInt(OTP) });
      setOTP("");
      if (!props.verifyOTPErr) {
        props.history.push("/");
      }
    }
  };

  return (
    <div className="dashboard container">
      <div className="card z-depth-0">
        <div className="card-content">
          <div className="row">
            <div className="col s12">
              <span className="card-title">Verify OTP</span>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <form className="login-form" autoComplete="off">
                <div className="input-field">
                  <label htmlFor="name"> OTP </label>
                  <input
                    type="number"
                    id="otp"
                    name="otp"
                    value={OTP}
                    onChange={handleChange}
                    max={999999}
                  />
                  {error && <p className="red-text"> {error} </p>}
                </div>

                <div className="input-field">
                  <button
                    className="btn z-depth-0"
                    type="submit"
                    onClick={handleSumbit}
                    disabled={!OTP}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    verifyOTPErr: state.verifyOTPErr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyOTPActionProp: (newUser) => verifyOTPAction(dispatch, newUser),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyOTP)
);
