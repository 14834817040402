const initState = {};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGNUP_SUCCESS":
      return { ...state, authErr: null };

    case "SIGNUP_ERROR":
      return { ...state, authErr: action.error };

    case "ADD_USER_SUCCESS":
      return { ...state, addUserErr: null };

    case "ADD_USER_ERROR":
      return { ...state, addUserErr: action.error };

    case "VERIFY_USER_EMAIL_SUCCESS":
      return {
        ...state,
        user: action.user,
        token: action.token,
        verifyEmailErr: null,
      };

    case "VERIFY_USER_EMAIL_ERROR":
      return { ...state, verifyEmailErr: action.error };

    case "VERIFY_USER_OTP_SUCCESS":
      return {
        ...state,
        user: action.user,
        token: action.token,
        verifyOTPErr: null,
      };

    case "VERIFY_MOBILE_FOR_PRODUCT_OTP_ERROR":
      return {
        ...state,
        verifyOTPForProductErr: action.error,
        has2FAAccess: false,
      };

    case "VERIFY_MOBILE_FOR_PRODUCT_OTP_SUCCESS":
      return {
        ...state,
        verifyOTPForProductErr: false,
        has2FAAccess: true,
      };

    case "REVOKE_FULL_READ_2FA_ACCESS":
      return {
        ...state,
        has2FAAccess: false,
      };

    case "VERIFY_USER_OTP_ERROR":
      return { ...state, verifyOTPErr: action.error };

    case "SET_USER":
      return { ...state, user: action.user };

    case "LOGOUT":
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      return { initState };

    default:
      return state;
  }
};

export default authReducer;
