import React from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import {MAP_API_KEY} from '../../config/config'

const MapLocation = (props) => {

    const mapStyles = {
        width: props.width || '70%',
        height: props.height || '50%'
    };

    const initialCenter = {
        lat: props.lat || -1.2884,
        lng: props.lng || -1.2884
    }

    return(
        <Map
            google={props.google}
            zoom={20}
            style={mapStyles}
            initialCenter={initialCenter}
            defaultCenter={{lat: props.lat, lng: props.lng}}
        >
            <Marker
                title={'The product was last scanned at'}
                name={'Last Scan'}
                position={{lat: props.lat, lng: props.lng }} />
    </Map>
    )
}

export default GoogleApiWrapper({
    apiKey: MAP_API_KEY
})(MapLocation)