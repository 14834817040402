import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { uploadCSV, importFromSap } from "../../store/actions/product.actions";
import "./upload.css";

export const Upload = (props) => {
  const [file, setFile] = useState();
  const [type, setType] = useState("product");
  const [uploadInfo, setUploadInfo] = useState();

  const loader = useSelector((state) => state.loader.loader);

  const handleChange = (e) => setFile(e.target.files[0]);
  const handleSubmit = () => {
    if (type == "sapImport") {
      props.importFromSap();
    } else {
      props.uploadCSV({ file, type });
    }
  };
  const handleSelect = (e) => {
    let _type = e.target.value;
    if (_type === "product")
      setUploadInfo(
        "The csv must contain fileds id, name, description, category, imagePath"
      );
    if (_type === "images")
      setUploadInfo("Upload the zip file with the images only");
    setType(_type);
  };

  return (
    <div className="dashboard container">
      <div className="card z-depth-0">
        <div className="card-content">
          <div className="row">
            <div className="col s6">
              <div className="row">
                <div className="col s12">
                  <span className="card-title">File Upload</span>
                </div>

                <div class="input-field col s12">
                  <select className="browser-default" onChange={handleSelect}>
                    <option disabled selected="selected">
                      {" "}
                      Choose the file type{" "}
                    </option>
                    <option value="product"> Product csv </option>
                    {/* <option value="rfid"> RFID CSV </option> */}
                    <option value="images"> Images ZIP </option>
                    <option value="sapImport"> Import from SAP </option>
                  </select>
                </div>

                <div className="col s12">
                  {uploadInfo && <span> {uploadInfo} </span>}
                </div>

                {type != "sapImport" && (
                  <div class="input-field col s12">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      onChange={handleChange}
                    />
                  </div>
                )}

                <div class="input-field col s12">
                  <label
                    className="custom-file-label"
                    htmlFor="customFile"
                  ></label>
                  <button
                    className="btn waves-effect waves-light"
                    onClick={handleSubmit}
                    disabled={loader}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadCSV: (data) => uploadCSV(dispatch, data),
  importFromSap: (_) => importFromSap(dispatch),
});

export default connect(null, mapDispatchToProps)(Upload);
