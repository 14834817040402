import handleRequest from "../../services/http.service";
import actionTypes, { LOADER } from "./actions-types";

export const signupAction = async (dispatch, newUser) => {
  let url = "/signup";
  let resp = await handleRequest({ method: "POST", url, data: newUser });
  debugger;
  try {
    if (resp.success) {
      let msg =
        "Signup successful. No need to verify mail as this is demo app and we trust you";
      dispatch({
        type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: false, msg },
      });
      dispatch({ type: "SIGNUP_SUCCESS", user: newUser });
    } else {
      dispatch({ type: "SIGNUP_ERROR", error: resp.error });
    }
  } catch (e) {
    let msg = e.response.data.message || "Something went wrong";
    dispatch({
      type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
      notification: { isError: true, msg },
    });
    dispatch({ type: "SIGNUP_ERROR", error: e });
    //let resp = handleRequest({method: 'GET', url})
  }
};

export const addUserAction = async (dispatch, user) => {
  let url = "/register-user";
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  };
  dispatch({
    type: LOADER.SHOW_LOADER,
  });
  let resp = await handleRequest({ method: "POST", url, data: user, config });
  try {
    if (resp.success) {
      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({
        type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: false, msg: resp.message },
      });
      dispatch({ type: "ADD_USER_SUCCESS", user });
    } else {
      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({
        type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: true, msg: resp.message },
      });
      dispatch({ type: "ADD_USER_ERROR", error: resp.error });
      if (resp.message === "Invalid Authentication Token") {
        dispatch({ type: "LOGOUT" });
      }
    }
  } catch (e) {
    let msg = e.response.data.message || "Something went wrong";
    dispatch({
      type: LOADER.HIDE_LOADER,
    });
    dispatch({
      type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
      notification: { isError: true, msg },
    });
    dispatch({ type: "ADD_USER_ERROR", error: e });
  }
};

export const verifyEmailAction = async (dispatch, user, token) => {
  let url = `/verify-email?token=${token}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  dispatch({
    type: LOADER.SHOW_LOADER,
  });
  let resp = await handleRequest({ method: "POST", url, data: user, config });
  try {
    if (resp.success) {
      localStorage.setItem("token", resp.data.token);

      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({
        type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: false, msg: resp.message },
      });
      dispatch({ type: "VERIFY_USER_EMAIL_SUCCESS", user });
    } else {
      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({ type: "VERIFY_USER_EMAIL_ERROR", error: resp.error });
      dispatch({
        type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: true, msg: resp.message },
      });
    }
  } catch (e) {
    dispatch({
      type: LOADER.HIDE_LOADER,
    });
    dispatch({
      type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
      notification: { isError: true, msg: "Something went wrong" },
    });
    dispatch({
      type: "VERIFY_USER_EMAIL_ERROR",
      error: "Something went wrong",
    });
  }
};

export const verifyOTPAction = async (dispatch, user) => {
  let url = "/verify-mobile-web";
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  };

  dispatch({
    type: LOADER.SHOW_LOADER,
  });
  let resp = await handleRequest({ method: "POST", url, data: user, config });
  try {
    if (resp.success) {
      // localStorage.setItem("token", resp.data.token);
      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({
        type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: false, msg: resp.message },
      });
      dispatch({ type: "VERIFY_USER_OTP_SUCCESS", user });
      dispatch({ type: "LOGOUT" });
    } else {
      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({ type: "VERIFY_USER_OTP_ERROR", error: resp.error });
    }
  } catch (e) {
    dispatch({
      type: LOADER.HIDE_LOADER,
    });
    dispatch({
      type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
      notification: { isError: true, msg: "Something went wrong" },
    });
    dispatch({ type: "VERIFY_USER_OTP_ERROR", error: "Something went wrong" });
  }
};

export const VerifyMobileOTPAction = async (dispatch, user) => {
  const token = localStorage.getItem("token");
  let url = "/verify-mobile";
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  };

  dispatch({
    type: LOADER.SHOW_LOADER,
  });
  let resp = await handleRequest({ method: "POST", url, data: user, config });
  try {
    if (resp.success) {
      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({
        type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: false, msg: resp.message },
      });
      dispatch({ type: "VERIFY_MOBILE_FOR_PRODUCT_OTP_SUCCESS", error: false });
    } else {
      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({
        type: "VERIFY_MOBILE_FOR_PRODUCT_OTP_ERROR",
        error: true,
      });
    }
  } catch (e) {
    dispatch({
      type: LOADER.HIDE_LOADER,
    });
    dispatch({
      type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
      notification: { isError: true, msg: "Something went wrong" },
    });
    dispatch({
      type: "VERIFY_MOBILE_FOR_PRODUCT_OTP_ERROR",
      error: true,
    });
  }
};

export const ResetPasswordAction = async (dispatch, user, token) => {
  let url = `/reset-password?token=${token}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  dispatch({
    type: LOADER.SHOW_LOADER,
  });
  let resp = await handleRequest({ method: "POST", url, data: user, config });
  try {
    if (resp.success) {
      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({
        type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: false, msg: resp.message },
      });
    } else {
      dispatch({
        type: LOADER.HIDE_LOADER,
      });
      dispatch({
        type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
        notification: { isError: true, msg: resp.message },
      });
    }
  } catch (e) {
    dispatch({
      type: LOADER.HIDE_LOADER,
    });
    dispatch({
      type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
      notification: { isError: true, msg: "Something went wrong" },
    });
  }
};
