import React, { useState } from "react";
import { connect } from "react-redux";

// import Section from "./Section";
import ScanRfid from "./ScanRfid";
import SelectProduct from "./SelectProduct";
import {
  fetchRfIdList,
  fetchUnLinkedProducts,
  linkProduct,
} from "../../store/actions/link.action";
import Popup from "../common/Popup";

export const Linking = (props) => {
  // const [rfIdList, setRfIdList] = useState([])
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedRfid, setSelectedRfid] = useState();

  const initialCall = async () => {
    //let _rfIdList = await props.fetchRfIdList()
    let _productList = await props.fetchUnLinkedProducts();
    _productList = _productList?.map((product) => ({
      ...product,
      name: product.category,
    }));
    setProductList(_productList);
    // setRfIdList(_rfIdList)
  };

  const setToInitialState = () => {
    let newProductList = productList.filter(
      (product) => product.productID != selectedProduct.productID
    );
    setProductList(newProductList);
    setSelectedProduct(undefined);
    setSelectedRfid(undefined);
  };

  useState(initialCall);

  const handleSelectProduct = (e) =>
    setSelectedProduct(JSON.parse(e.target.value));

  const handleLinking = async () => {
    //return <Popup product={selectedProduct} />
    let isSuccess = await props.linkProduct({
      uid: selectedRfid,
      productid: selectedProduct.productID,
    });
    if (isSuccess) setToInitialState();
  };

  return (
    <div className="dashboard container">
      <div className="card z-depth-0">
        <div className="card-content">
          <div className="row">
            <div className="col s6">
              <ScanRfid
                // options={rfIdList}
                key="_id"
                value="uid"
                sectionTitle={"RFID"}
                selectedRfid={selectedRfid}
                setSelectedRfid={setSelectedRfid}
              />
            </div>
            <div className="col s6">
              <SelectProduct
                options={productList}
                key="_id"
                value="productID"
                sectionTitle={"Product"}
                onSelect={handleSelectProduct}
                submitBtnName="Link Product"
                handleSubmit={handleLinking}
                btnDisabled={selectedProduct && selectedRfid ? false : true}
                selectedProduct={selectedProduct}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => ({
  fetchRfIdList,
  fetchUnLinkedProducts: () => fetchUnLinkedProducts(dispatch),
  linkProduct: (payload) => linkProduct(dispatch, payload),
});

export default connect(mapStateToProps, mapDispatchToProps)(Linking);
