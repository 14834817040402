import React, { useState } from "react";
import { IMAGE_PATH } from "../../config/config";

const Popup = ({ product }) => {
  return (
    <div id="modal1" className="modal">
      <div className="modal-content">
        <h4>Product Details</h4>

        <div className="col s4">
          {product && product.imagePath && (
            <img
              class="responsive-img productImage"
              src={`${IMAGE_PATH}/${product.imagePath}`}
            />
          )}

          {/* <img className="responsive-img productImage" src="https://source.unsplash.com/random" /> */}
        </div>
        <div className="col s8">
          <table>
            <tbody>
              <tr>
                <td className="head-col">Product Name</td>
                <td>{product && product.productName}</td>
              </tr>
              <tr>
                <td className="head-col">Category</td>
                <td>{product && product.category}</td>
              </tr>
              <tr>
                <td className="head-col">Description</td>
                <td>{product && product.description}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <a href="#!" className="modal-close waves-effect waves-green btn-flat">
          Agree
        </a>
      </div>
    </div>
  );
};

export default Popup;
