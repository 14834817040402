import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { fetchProduct } from '../../store/actions/product.actions'
import MapLocation from './MapLocation'
import CardComponent from './CardComponent'
import moment from 'moment'
import { IMAGE_PATH } from '../../config/config'
import VerifyOTP from '../auth/verifyOTP'
import { VerifyMobileOTPAction } from '../../store/actions/auth.action'
import Spinner from '../spinner/spinner'
import handleRequest from '../../services/http.service'
import VerifyOTPModal from '../auth/VerifyOTPModal'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '50px',
        transform: 'translate(-50%, -50%)',
    },
}

export const ProductDetails = (props) => {
    let [productId, setProductId] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [isSendingOTP, setIsSendingOTP] = useState(false)
    const [hasError, setHasError] = useState('')

    const isAdmin = props.user?.role === 'admin'
    const isSimpleRead = props.user?.role === 'simpleRead'
    const isFullRead = props.user?.role === 'fullRead'
    const isSeller = props.user?.role === 'seller'
    const isFullRead2FARole = props.user?.role === 'fullRead2FA'
    const isFullRead2FA = props.user?.role === 'fullRead2FA' && !props.fullAccess?.verifyOTPForProductErr && props.fullAccess?.has2FAAccess

    console.log({ isFullRead2FARole })
    console.log({ isFullRead2FA })
    console.log(props.user)

    useEffect(() => {
        if (props.user?.verifyOTPForProductErr) {
            setHasError(props.user?.verifyOTPForProductErr)
        }
    }, [props.user?.verifyOTPForProductErr])

    const handleChange = (e) => {
        setProductId(e.target.value)
    }

    useEffect(() => {
        // if (!productId) props.emptyProduct1();
        return () => {
            if (isAdmin || isSimpleRead || isFullRead) {
                props.emptyProduct1()
            }
        }
    }, [productId])

    useEffect(() => {
        let id = props.match?.params && props.match.params?.id

        if (id && id != productId) {
            handleChange({ target: { value: id } })
            props.fetchProduct1(id)
        }
    }, [productId])

    const handleSubmit = async (e) => {
        props.remove2FAAccess()
        productId && props.fetchProduct1(productId)
    }

    const returnImageURL = (imagePath) => {
        if (Array.isArray(imagePath)) return `${IMAGE_PATH}/${imagePath[0]}`
        else return `${IMAGE_PATH}/${imagePath}`
    }

    const openMap = (lat, lng) => {
        return `https://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${lng}`
        // window.open(url, '_blank')
    }

    const handleSumbitModal = (OTP) => {
        console.log({ OTP })
        props.verifyOTPActionProp({ otp: parseInt(OTP) })
        // setShowModal(false);
    }

    async function handleGetFullDetailsClick(e) {
        e.preventDefault()
        setIsSendingOTP(true)
        const url = '/resend-otp'
        const token = localStorage.getItem('token')
        const config = { headers: { Authorization: 'Bearer ' + token } }

        try {
            const resp = await handleRequest({
                method: 'POST',
                url,
                data: {},
                config,
            })

            if (resp && resp.success) {
                setIsSendingOTP(false)
                setShowModal(true)
                return
            } else {
                setHasError('Something Went Wrong')
            }
        } catch (error) {
            setHasError('Something Went Wrong')
        }
    }

    const renderTagDetails = () => {
        let { ledgerData: ledger, product } = props.product
        return (
            <div>
                <span class='card-title'>Tag Details</span>
                <table>
                    <tbody>
                        <tr>
                            <td className='head-col'>UID</td>
                            <td>{product && product.productUId}</td>
                        </tr>
                        <tr>
                            <td className='head-col'>Product Id</td>
                            <td>{product && product.productID}</td>
                        </tr>
                        <tr>
                            <td className='head-col'>RFID Tag Status</td>
                            {product && <td className={product.isOkay ? 'green-txt' : 'red-txt'}>{product.isOkay ? 'Ok - Working' : 'Tempered - Not working'}</td>}
                        </tr>
                    </tbody>
                </table>

                {isFullRead2FARole && (
                    <div className='input-field col s12'>
                        <div className='row'>
                            <button className='btn waves-effect waves-light' type='button' onClick={handleGetFullDetailsClick}>
                                View Full Details
                                <i className='material-icons right'>send</i>
                            </button>
                        </div>
                        {hasError && <span style={{ color: 'red' }}>{hasError}</span>}
                    </div>
                )}
            </div>
        )
    }

    const renderBasicProductDetails = () => {
        let { product } = props.product
        return (
            <div>
                <span class='card-title mb-5'>Product Details</span>
                <div className='col s4'>
                    {product && product.imagePath?.length > 0 ? (
                        <img className='responsive-img productImage' src={returnImageURL(product.imagePath)} />
                    ) : (
                        <div
                            className='responsive-img'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '159px',
                            }}
                        >
                            No Image
                        </div>
                    )}

                    {/* <img className="responsive-img productImage" src="https://source.unsplash.com/random" /> */}
                </div>
                <div className='col s8'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='head-col'>Product Name</td>
                                <td>{(product && product.productName) || '-'}</td>
                            </tr>
                            <tr>
                                <td className='head-col'>Category</td>
                                <td>{product && product.category}</td>
                            </tr>
                            <tr>
                                <td className='head-col'>Description</td>
                                <td>{product && product.description}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const renderScans = () => {
        let { ScannedList: scans } = props.product.product
        return (
            <div>
                <span class='card-title'>Scans </span>
                <table>
                    <thead>
                        <td> Date/ Time</td>
                        <td> Location </td>
                        <td> User </td>
                        <td> RFID Tag Status </td>
                    </thead>
                    <tbody>
                        {scans &&
                            scans.map((scan) => {
                                // let location = scan.scanLocation;
                                // let latLong = location.split(",");
                                return (
                                    <tr>
                                        <td className='w25'> {moment(scan.scannedAt).format('MMMM Do YYYY, h:mm a')} </td>
                                        <td className='w25'>
                                            <a href={openMap(scan.latitude, scan.longitude)} target='_blank'>
                                                <i className='material-icons'> where_to_vote </i>
                                            </a>
                                        </td>
                                        {/* <td> <MapLocation lat={scan.latitude} lng={scan.longitude} height={"20%"} width={"20%"} /></td> */}
                                        <td className='w25'> {scan.scannedBy} </td>
                                        <td className={props.product?.product?.isOkay ? 'green-txt' : 'red-txt'}>{props.product?.product?.isOkay == undefined ? '-' : props.product?.product?.isOkay ? 'Ok - Working' : 'Tempered - Not working'}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        )
    }

    const renderLastScan = () => {
        let { ScannedList } = props.product.product
        const lastScan = ScannedList[0] || {}
        // const location = lastScan.scanLocation.split(",");
        return (
            <div className='dashboard container'>
                <div class='card z-depth-0'>
                    <div class='card-content'>
                        <div class='row'>
                            <span class='card-title'>Last Scan Location </span>
                            <div className='map'>
                                <MapLocation lat={lastScan?.latitude || ''} lng={lastScan?.longitude || ''} height='500px' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderProductAdvancedDetails = () => {
        let { ledgerData: ledger, product } = props.product
        const createdAt = moment(product?.createdAt).format('MMMM Do YYYY, h:mm a')
        return (
            <div>
                <span class='card-title'>Advanced Details</span>
                <table>
                    <tbody>
                        <tr>
                            <td className='head-col'>Created Time</td>
                            <td>{!createdAt || createdAt === 'Invalid date' ? '-' : createdAt}</td>
                        </tr>

                        <tr>
                            <td className='head-col'>Tx Id </td>
                            <td>{ledger && ledger.trail && ledger.trail[0] && ledger.trail[0].txID}</td>
                        </tr>

                        <tr>
                            <td className='head-col'>Created by</td>
                            <td>{ledger && ledger.trail && ledger.trail[0] && ledger.trail[0].companyName}</td>
                        </tr>

                        <tr>
                            <td className='head-col'>Comment</td>
                            <td>{ledger && ledger.trail && ledger.trail[0] && ledger.trail[0].message}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    const renderProductDetails = () => {
        let { ledgerData: ledger, product } = props.product
        return (
            <div>
                <span class='card-title'>Product Details</span>
                <table>
                    <tbody>
                        <tr>
                            <td>UID</td>
                            <td>{product && product.uid}</td>
                        </tr>
                        <tr>
                            <td>Product Id</td>
                            <td>{product && product.id}</td>
                        </tr>
                        <tr>
                            <td>Product Name</td>
                            <td>{product && product.productName}</td>
                        </tr>
                        <tr>
                            <td>Category</td>
                            <td>{product && product.category}</td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>{product && product.description}</td>
                        </tr>

                        <tr>
                            <td>Created Time</td>
                            <td>{ledger && ledger.preCheckInReciever}</td>
                        </tr>

                        <tr>
                            <td>Tx Id </td>
                            <td>{ledger && ledger.trail && ledger.trail[0] && ledger.trail[0].txID}</td>
                        </tr>

                        <tr>
                            <td>Created by</td>
                            <td>{ledger && ledger.trail && ledger.trail[0] && ledger.trail[0].companyName}</td>
                        </tr>

                        <tr>
                            <td>Comment</td>
                            <td>{ledger && ledger.trail && ledger.trail[0] && ledger.trail[0].message}</td>
                        </tr>

                        <tr>
                            <h4> Last Location: </h4>
                        </tr>
                        <tr>
                            <MapLocation />
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    const searchForm = () => {
        return (
            <form className='col s12'>
                <div className='row'>
                    <div className='col s6'>
                        <div className='row'>
                            <div className='input-field '>
                                <input id='id' type='text' className='validate' value={productId} onChange={handleChange} />
                                <label htmlFor='id' className={productId != '' ? 'active' : ''}>
                                    Product Id{' '}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='input-field col s12'>
                        <div className='row'>
                            <button className='btn waves-effect waves-light' type='button' onClick={handleSubmit}>
                                View
                                <i className='material-icons right'>send</i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    return (
        <>
            <CardComponent cardTitle='Product Details'>{searchForm()}</CardComponent>

            {props.product && Object.entries(props.product) && Object.entries(props.product).length !== 0 && <CardComponent> {renderTagDetails()} </CardComponent>}

            {(isAdmin || isFullRead || isFullRead2FA || isSeller) && props.product && Object.entries(props.product) && Object.entries(props.product).length !== 0 && <CardComponent> {renderBasicProductDetails()} </CardComponent>}

            {(isAdmin || isFullRead || isFullRead2FA || isSeller) && props.product && Object.entries(props.product) && Object.entries(props.product).length !== 0 && <CardComponent> {renderProductAdvancedDetails()} </CardComponent>}

            {(isAdmin || isFullRead || isFullRead2FA || isSeller) && props.product && props.product.product && props.product.product.ScannedList && <CardComponent> {renderScans()} </CardComponent>}

            {(isAdmin || isFullRead || isFullRead2FA || isSeller) &&
                props.product &&
                props.product.product &&
                props.product.product.ScannedList &&
                props.product.product.ScannedList.length > 0 &&
                // <CardComponent> { renderLastScan() } </CardComponent>
                renderLastScan()}

            <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={customStyles}>
                <VerifyOTPModal onSubmit={handleSumbitModal} />
            </Modal>

            {isSendingOTP && <Spinner />}
        </>
    )
}

//   return (
//     <div className="dashboard container">
//       <div className="card z-depth-0">
//         <div className="card-content" >
//           <div className="row">
//             <span className="card-title"> Product Details </span>
//             <form className="col s12">
//               <div className="row">
//                 <div className="input-field col s6">
//                   <input id="id" type="text" className="validate"  value={productId} onChange={handleChange} />
//                   <label htmlFor="id" className={productId!= '' ? 'active': ''} >Product Id </label>
//                 </div>
//                 <div className="input-field col s6">
//                   <button className="btn waves-effect waves-light" type="button" onClick={handleSubmit}>View
//                   <i className="material-icons right">send</i>
//                 </button>

//                 </div>
//               </div>
//             </form>
//           </div>

//           {/* { props.product && Object.entries(props.product).length !== 0 && renderProductDetails() } */}
//           {props.product && Object.entries(props.product).length !== 0 && renderTagDetails()}
//           {props.product && Object.entries(props.product).length !== 0 &&  renderBasicProductDetails()}
//           {props.product && Object.entries(props.product).length !== 0 &&  renderProductAdvancedDetails()}
//           {props.product && props.product.scans && props.product.scans.length !== 0 &&  renderLastLocation()}
//         </div>
//       </div>
//     </div>
//   )
// }

const mapStateToProps = (state) => {
    return {
        product: state.products.product,
        user: state.auth.user,
        fullAccess: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    const token = localStorage.getItem('token')

    return {
        fetchProduct1: async (product) => fetchProduct(dispatch, product, token),
        verifyOTP: async () => VerifyMobileOTPAction(token),
        emptyProduct1: () => dispatch({ type: 'EMPTY_PRODUCT' }),
        remove2FAAccess: () => dispatch({ type: 'REVOKE_FULL_READ_2FA_ACCESS' }),
        verifyOTPActionProp: (newUser) => VerifyMobileOTPAction(dispatch, newUser),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
