const initState = {
    product: {},
    productList: [],
    totalProducts: null,
}

const productReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_PRODUCT_SUCCESS':
            return {
                ...state,
                product: action.product,
            }

        case 'FETCH_PRODUCT_SUCCESS':
            return {
                ...state,
                product: action.product,
            }

        case 'EMPTY_PRODUCT':
            return {
                ...state,
                product: {},
            }
        case 'FETCH_PRODUCT_LIST_SUCCESS':
            return {
                ...state,
                totalProducts: action.totalRecords,
                productList: action.productList,
            }
        case 'UPDATE_PRODUCT_SUCCESS':
            return {
                ...state,
                product: action.product,
            }
        case 'UPDATE_PRODUCT_ISSOLD_STATUS_SUCCESS':
            return {
                ...state,
                product: action.product,
            }

        default:
            return state
    }
}

export default productReducer
