import axios from "axios";

import { API_ENDPOINT } from "../config/config";
import history from "../common/history";

const handleRequest = async ({ method, url, data, config }) => {
  try {
    url = API_ENDPOINT + url;
    if (method === "GET") {
      let resp = await axios.get(url, { params: data }, config);
      return await handleResponse(resp);
    } else if (method === "POST") {
      let resp = await axios.post(url, data, config);
      return await handleResponse(resp);
    } else if (method == "POST_IMAGE") {
      // let newHeader = { 'Content-Type': 'multipart/form-data' }
      config.headers["Content-type"] = "multipart/form-data";
      const formData = new FormData();
      let keys = Object.keys(data);
      let values = Object.values(data);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] == "image") {
          let images = values[i];
          for (let j = 0; j < images.length; j++) {
            formData.append("image", images[j], images[j].name);
          }
          //formData.append('image', values[i], values[i].name)
        } else {
          formData.append(keys[i], values[i]);
        }
      }
      let resp = await axios.post(url, formData, config);
      return await handleResponse(resp);
    } else if (method == "POST_FILE") {
      config.headers["Content-type"] = "multipart/form-data";
      const formData = new FormData();
      formData.append("file", data.file);
      let resp = await axios.post(url, formData, config);
      return await handleResponse(resp);
    } else if (method === "GET_WITH_AUTH") {
      const token = localStorage.getItem("token");
      let resp = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return await handleResponse(resp);
    }
  } catch (error) {
    return await handleResponse(error.response || error);
    // throw error
  }
};

const handleResponse = async (resp) => {
  try {
    let { status } = resp;
    if (!status) status = resp.response && resp.response.status;
    if (status == 401) {
      await handleLogout();
      history.push("/login");
      return { success: false, message: "Unauthorized" };
    } else if (resp && resp.data) {
      return resp.data;
    } else {
      return { success: false, message: "Sorry something broke !!" };
    }
  } catch (error) {
    return { success: false, message: "Sorry something broke !!" };
  }
};

const handleLogout = async () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

const handleServerError = async () => {
  // redirect to error page
  console.log("We shall redirect to error page here");
};

export default handleRequest;
