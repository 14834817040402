const actionTypes = {
    AUTH_ACTIONS: {
        SIGNUP_ERROR: 'SIGNUP_ERROR',
        SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
        ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
        ADD_USER_ERROR: 'ADD_USER_ERROR',
    },

    NOTIFICATION: {
        SHOW_NOTFICATION: 'SHOW_NOTFICATION',
    },

    LOADER: {
        SHOW_LOADER: 'SHOW_LOADER',
        HIDE_LOADER: 'HIDE_LOADER',
    },

    PRODUCT: {
        FETCH_PRODUCT_SUCCESS: 'FETCH_PRODUCT_SUCCESS',
        FETCH_PRODUCT_ERR: 'FETCH_PRODUCT_ERR',
        ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
        ADD_PRODUCT_ERR: 'ADD_PRODUCT_ERR',
        UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
        UPDATE_PRODUCT_ERR: 'UPDATE_PRODUCT_ERR',
        UPDATE_PRODUCT_ISSOLD_STATUS_SUCCESS: 'UPDATE_PRODUCT_ISSOLD_STATUS_SUCCESS',
        UPDATE_PRODUCT_ISSOLD_STATUS_ERR: 'UPDATE_PRODUCT_ISSOLD_STATUS_ERR',
        FETCH_PRODUCT_LIST_SUCCESS: 'FETCH_PRODUCT_LIST_SUCCESS',
        FETCH_PRODUCT_LIST_ERR: 'FETCH_PRODUCT_LIST_ERR',
        UPDATE_SCAN_SUCCESS: 'UPDATE_SCAN_SUCCESS',
        UPDATE_SCAN_ERR: 'UPDATE_SCAN_ERR',
    },

    USER: {
        DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
        DELETE_USER_ERR: 'DELETE_USER_ERR',
        UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
        UPDATE_USER_ERR: 'UPDATE_USER_ERR',
        FETCH_USER_LIST_SUCCESS: 'FETCH_USER_LIST_SUCCESS',
        FETCH_USER_LIST_ERR: 'FETCH_USER_LIST_ERR',
    },
}

module.exports = {
    NOTIFICATION: actionTypes.NOTIFICATION,
    AUTH_ACTIONS: actionTypes.AUTH_ACTIONS,
    PRODUCT: actionTypes.PRODUCT,
    USER: actionTypes.USER,
    LOADER: actionTypes.LOADER,
    actionTypes,
}
