import React from 'react'

const CardComponent = (props) => {
    return (
        <div className="dashboard container">
            <div className="card z-depth-0">
                <div className="card-content" >
                    <div className="row">
                        <span className="card-title"> {props.cardTitle} </span>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
 
    )
}

export default CardComponent