import React, { useState } from "react";

const SelectProduct = (props) => {
  return (
    <div className="card z-depth-0">
      <span className="card-title"> {props.sectionTitle} </span>
      <div className="input-field">
        <select className="browser-default" onChange={props.onSelect}>
          <option disabled selected="selected">
            {" "}
            Choose the product to link ...{" "}
          </option>
          {props.options.map((option) => (
            <option value={JSON.stringify(option)}>
              {" "}
              {option[props.value]}{" "}
            </option>
          ))}
        </select>
        {props.selectedProduct && (
          <div>
            <ul className="product-data">
              <li> Id: {props.selectedProduct.productID}</li>
              <li> UId: {props.selectedProduct.productUId}</li>
              <li> Description: {props.selectedProduct.description || "-"}</li>
              <li> Category: {props.selectedProduct.category || "-"}</li>
              {/* <li>
                {" "}
                Added From: {props.selectedProduct.trail[0].companyName || "-"}
              </li> */}
            </ul>
          </div>
        )}
      </div>
      {props.submitBtnName && (
        <div>
          <button
            className="btn z-depth-0"
            onClick={props.handleSubmit}
            disabled={props.btnDisabled}
          >
            {props.submitBtnName}
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectProduct;
