import handleRequest from './../../services/http.service'
import { LOADER, NOTIFICATION, PRODUCT } from './actions-types'
import { getErrorMessage } from '../../services/helper.service'

export const addProduct = async (dispatch, product) => {
    let token = localStorage.getItem('token')
    var config = { headers: { Authorization: 'Bearer ' + token } }
    let url = '/product/add-product'
    try {
        dispatch({
            type: LOADER.SHOW_LOADER,
        })
        let resp = await handleRequest({
            method: 'POST_IMAGE',
            url,
            data: product,
            config,
        })
        if (resp.success) {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: false, msg: 'Product added successfully' },
            })
            dispatch({ type: PRODUCT.ADD_PRODUCT_SUCCESS, product })
        } else {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: true, msg: resp.message },
            })
            dispatch({ type: PRODUCT.ADD_PRODUCT_ERR, message: resp.message })
            if (resp.message === 'Invalid Authentication Token') {
                dispatch({ type: 'LOGOUT' })
            }
        }
    } catch (e) {
        dispatch({
            type: LOADER.HIDE_LOADER,
        })
        let message = e.response.data.message || 'Something went wrong'
        dispatch({
            type: NOTIFICATION.SHOW_NOTFICATION,
            notification: { isError: true, msg: message },
        })
        dispatch({ type: PRODUCT.ADD_PRODUCT_ERR, message })
    }
}

export const updateProduct = async (dispatch, product) => {
    let token = localStorage.getItem('token')
    var config = {
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    let url = '/product/update-product'
    try {
        dispatch({
            type: LOADER.SHOW_LOADER,
        })
        let resp = await handleRequest({
            method: 'POST',
            url,
            data: product,
            config,
        })
        if (resp.success) {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: false, msg: 'Product Updated successfully' },
            })
            dispatch({ type: PRODUCT.ADD_PRODUCT_SUCCESS, product })
        } else {
            // dispatch({
            //   type: NOTIFICATION.SHOW_NOTFICATION,
            //   notification: { isError: true, msg: resp.message },
            // });
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({ type: PRODUCT.ADD_PRODUCT_ERR, message: resp.message })
            if (resp.message === 'Invalid Authentication Token') {
                dispatch({ type: 'LOGOUT' })
            }
        }
    } catch (e) {
        let message = 'Something went wrong'
        dispatch({
            type: LOADER.HIDE_LOADER,
        })
        dispatch({
            type: NOTIFICATION.SHOW_NOTFICATION,
            notification: { isError: true, msg: message },
        })
        dispatch({ type: PRODUCT.ADD_PRODUCT_ERR, message })
    }
}

export const updateProductSoldStatus = async (dispatch, product, page) => {
    let token = localStorage.getItem('token')
    var config = {
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }
    let url = '/product/mark-sold'
    try {
        dispatch({
            type: LOADER.SHOW_LOADER,
        })
        let resp = await handleRequest({
            method: 'POST',
            url,
            data: product,
            config,
        })
        if (resp.success) {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            await fetchProductList(dispatch, { perPage: 10, pageNo: page })
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: false, msg: 'Product Updated successfully' },
            })
            dispatch({ type: PRODUCT.UPDATE_PRODUCT_ISSOLD_STATUS_SUCCESS, product })
        } else {
            // dispatch({
            //   type: NOTIFICATION.SHOW_NOTFICATION,
            //   notification: { isError: true, msg: resp.message },
            // });
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({ type: PRODUCT.UPDATE_PRODUCT_ISSOLD_STATUS_ERR, message: resp.message })
            if (resp.message === 'Invalid Authentication Token') {
                dispatch({ type: 'LOGOUT' })
            }
        }
    } catch (e) {
        let message = 'Something went wrong'
        dispatch({
            type: LOADER.HIDE_LOADER,
        })
        dispatch({
            type: NOTIFICATION.SHOW_NOTFICATION,
            notification: { isError: true, msg: message },
        })
        dispatch({ type: PRODUCT.ADD_PRODUCT_ERR, message })
    }
}

export const fetchProduct = async (dispatch, id, token) => {
    let url = `/product/fetch/${id}`
    var config = { headers: { Authorization: 'Bearer ' + token } }
    try {
        dispatch({
            type: LOADER.SHOW_LOADER,
        })
        let resp = await handleRequest({ method: 'POST', url, config })
        if (resp && resp.success) {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: false, msg: 'Details loaded' },
            })
            dispatch({ type: PRODUCT.FETCH_PRODUCT_SUCCESS, product: resp.data })
        } else {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: true, msg: resp.message },
            })
            dispatch({ type: PRODUCT.FETCH_PRODUCT_ERR, product: resp.message })
            if (resp.message === 'Invalid Authentication Token') {
                dispatch({ type: 'LOGOUT' })
            }
        }
    } catch (e) {
        let message = e.message || 'Something went wrong'
        dispatch({
            type: LOADER.HIDE_LOADER,
        })
        dispatch({
            type: NOTIFICATION.SHOW_NOTFICATION,
            notification: { isError: true, msg: message },
        })
        dispatch({ type: PRODUCT.FETCH_PRODUCT_ERR, message })
    }
}

export const fetchProductList = async (dispatch, data) => {
    let url = `/product/list-products`
    //let token = localStorage.getItem('token')
    //var config = { headers: {'Authorization': "BearhandleChangeer " + token} }
    try {
        dispatch({
            type: LOADER.SHOW_LOADER,
        })
        let resp = await handleRequest({ method: 'GET_WITH_AUTH', url, data })
        if (resp && resp.success) {
            // dispatch({
            //   type: NOTIFICATION.SHOW_NOTFICATION,
            //   notification: { isError: false, msg: "List loaded" },
            // });
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({
                type: PRODUCT.FETCH_PRODUCT_LIST_SUCCESS,
                productList: resp.data.products,
                totalRecords: resp.data.totalRecords,
            })
        } else {
            // dispatch({
            //   type: NOTIFICATION.SHOW_NOTFICATION,
            //   notification: { isError: true, msg: resp.message },
            // });
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({ type: PRODUCT.FETCH_PRODUCT_LIST_ERR, product: resp.message })
            if (resp.message === 'Invalid Authentication Token') {
                dispatch({ type: 'LOGOUT' })
            }
        }
    } catch (e) {
        let message = getErrorMessage(e)
        // dispatch({
        //   type: NOTIFICATION.SHOW_NOTFICATION,
        //   notification: { isError: true, msg: message },
        // });
        dispatch({
            type: LOADER.HIDE_LOADER,
        })
        dispatch({ type: PRODUCT.FETCH_PRODUCT_LIST_ERR, message })
    }
}

export const uploadCSV = async (dispatch, data) => {
    let token = localStorage.getItem('token')
    let url
    var config = { headers: { Authorization: 'Bearer ' + token } }
    if (data.type == 'product') url = '/product/upload-product-details'
    if (data.type == 'rfid') url = '/product/upload-rfid'
    if (data.type == 'images') url = '/product/upload-images'
    try {
        dispatch({
            type: LOADER.SHOW_LOADER,
        })
        let resp = await handleRequest({ method: 'POST_FILE', url, data, config })
        if (resp.success) {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: false, msg: 'File uploaded successfully' },
            })
            //dispatch({ type: PRODUCT.FILE_UPLOAD_SUCCESS, data})
        } else {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: true, msg: resp.message },
            })
            //dispatch({ type: PRODUCT.FILE_UPLOAD_ERR, message: resp.message})
            if (resp.message === 'Invalid Authentication Token') {
                dispatch({ type: 'LOGOUT' })
            }
        }
    } catch (e) {
        let message = getErrorMessage(e)
        dispatch({
            type: LOADER.HIDE_LOADER,
        })
        dispatch({
            type: NOTIFICATION.SHOW_NOTFICATION,
            notification: { isError: true, msg: message },
        })
        //dispatch({ type: PRODUCT.FILE_UPLOAD_ERR, message: resp.message})
    }
}

export const importFromSap = async (dispatch) => {
    let token = localStorage.getItem('token')
    let url = '/product/import-from-sap'
    let config = { headers: { Authorization: 'Bearer ' + token } }
    try {
        let resp = await handleRequest({ method: 'POST', url, config })
        if (resp.success) {
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: false, msg: 'Product Data imported from SAP' },
            })
            //dispatch({ type: PRODUCT.FILE_UPLOAD_SUCCESS, data})
        } else {
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: true, msg: resp.message },
            })
            //dispatch({ type: PRODUCT.FILE_UPLOAD_ERR, message: resp.message})
            if (resp.message === 'Invalid Authentication Token') {
                dispatch({ type: 'LOGOUT' })
            }
        }
    } catch (error) {
        let message = getErrorMessage(error)
        dispatch({
            type: NOTIFICATION.SHOW_NOTFICATION,
            notification: { isError: true, msg: message },
        })
    }
}

export const updateScanDetails = async (dispatch, product) => {
    let token = localStorage.getItem('token')
    var config = { headers: { Authorization: 'Bearer ' + token } }
    let url = '/product/update-scan'
    try {
        dispatch({
            type: LOADER.SHOW_LOADER,
        })
        let resp = await handleRequest({
            method: 'POST',
            url,
            data: product,
            config,
        })
        if (resp.success) {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            dispatch({
                type: NOTIFICATION.SHOW_NOTFICATION,
                notification: { isError: false, msg: 'Scan Updated successfully' },
            })
        } else {
            dispatch({
                type: LOADER.HIDE_LOADER,
            })
            if (resp.message === 'Invalid Authentication Token') {
                dispatch({ type: 'LOGOUT' })
            }
        }
    } catch (e) {
        let message = 'Something went wrong'
        dispatch({
            type: LOADER.HIDE_LOADER,
        })
        dispatch({
            type: NOTIFICATION.SHOW_NOTFICATION,
            notification: { isError: true, msg: message },
        })
    }
}
