import React, { useState, useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { fetchProductList, updateProductSoldStatus } from '../../store/actions/product.actions'
import { withRouter } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import ProductsQrCode from './ProductsQrCode'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import Spinner from '../spinner/spinner'
import handleRequest from '../../services/http.service'

const PER_PAGE = 10

const ListProduct = (props) => {
    const [searchText, setSearchText] = useState('')
    const [isSearching, setIsSearching] = useState(false)
    const [searchedTableData, setSearchedTableData] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [modal, setModal] = useState(false)
    const [product, setProduct] = useState('')
    const [hasFetched, setHasFetched] = useState(false)
    const isAdmin = props.user?.role === 'admin'
    const toggle = () => setModal(!modal)
    const offset = currentPage * PER_PAGE
    const handleSearchChange = (e) => setSearchText(e.target.value)
    const [isLoading, setIsLoading] = useState(false)
    const loader = useSelector((state) => state.loader.loader)
    const user = JSON.parse(localStorage.getItem('user'))
    const [newProducts, setNewProducts] = useState([])
    const [isProductLoading, setIsProductLoading] = useState(false)
    let url = `/product/list-products`

    const fetchAllProducts = async () => {
        try {
            setIsProductLoading(true)
            let resp = await handleRequest({ method: 'GET_WITH_AUTH', url })
            if (resp?.success) {
                setNewProducts(resp?.data?.products)
            }
            setIsProductLoading(false)
        } catch (err) {
            setIsProductLoading(false)
            console.log(err)
        }
    }

    useEffect(() => {
        fetchAllProducts()
    }, [])

    const handlePageChange = (data) => {
        setCurrentPage(data.selected)
    }

    const handleSearch = () => {
        setIsSearching(true)
        const newUserList = newProducts.filter((user) => {
            const regex = new RegExp(searchText, 'gi')
            return Object.values(user).some((userItem) => {
                return userItem.toString().match(regex)
            })
        })
        setSearchedTableData(newUserList)
    }

    const getTableData = useMemo(() => {
        let data = []
        if (isSearching) {
            data = searchedTableData
        } else {
            data = newProducts
        }
        const currentPageData = data.slice(offset, offset + PER_PAGE)
        return currentPageData
    }, [isSearching, searchedTableData, newProducts, offset])

    const [selectedItems, setSelectedItems] = useState([])

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked
        const selected = isChecked ? getTableData.map((item) => item.productUId) : []
        setSelectedItems(selected)
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs

    const getImageDataUrl = async (imageUrl) => {
        const response = await fetch(imageUrl)
        const blob = await response.blob()
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => {
                resolve(reader.result)
            }
            reader.readAsDataURL(blob)
        })
    }

    const generatePDF = async (items) => {
        setIsLoading(true)
        const docDefinition = {
            content: [],
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i]
            const imageDataUrl = await getImageDataUrl(`https://api.bctsl.de/uploads/qrcodes/${item}.png`)

            docDefinition.content.push(
                {
                    image: imageDataUrl,
                    width: 200,
                    alignment: 'center',
                    margin: [0, 10],
                },
                { text: item, fontSize: 16, bold: true, alignment: 'center', margin: [0, 10] },
                { text: item.description, alignment: 'center', margin: [0, 10] },
            )

            if (i !== items.length - 1) {
                docDefinition.content.push({ text: '', pageBreak: 'after' })
            }
        }

        pdfMake.createPdf(docDefinition).download('products-QR.pdf')
        setIsLoading(false)
    }

    const searchBox = () => {
        return (
            <div className='searchBox'>
                <input id='search' type='search' placeholder='Enter Search Text' value={searchText} onChange={handleSearchChange} style={{ marginRight: '10px' }} />
                <button className='btn waves-effect waves-light' type='button' onClick={handleSearch}>
                    {' '}
                    Search{' '}
                </button>
                <button
                    className='btn waves-effect waves-light'
                    style={{ marginRight: '0px' }}
                    type='button'
                    onClick={() => {
                        generatePDF(selectedItems)
                    }}
                >
                    Generate QR
                </button>
            </div>
        )
    }

    const handleSelectItem = (productUId) => {
        const isSelected = selectedItems.includes(productUId)
        let updatedSelectedItems = []

        if (isSelected) {
            updatedSelectedItems = selectedItems.filter((id) => id !== productUId)
        } else {
            updatedSelectedItems = [...selectedItems, productUId]
        }

        setSelectedItems(updatedSelectedItems)
    }

    return (
        <div className='dashboard container'>
            <div className='card z-depth-0'>
                {(isLoading || loader || isProductLoading) && (
                    <>
                        <div id='outer-div' style={{ width: '100%', height: '100%', background: '#fff', position: 'absolute', zIndex: 10, opacity: 0.5 }} />
                        {(isLoading || isProductLoading) && <Spinner />}
                    </>
                )}
                <div className='card-content'>
                    <div className='row'>
                        <div className='col s6'>
                            <span className='card-title'> Product List </span>
                        </div>
                        <div className='col s6 right-align'>{searchBox()}</div>

                        <div className='col s12'>
                            <table className='striped responsive-table'>
                                <thead>
                                    <tr>
                                        <th>
                                            <label style={{ padding: '0px 0px 15px 15px' }}>
                                                <input type='checkbox' onChange={handleSelectAll} />
                                                <span className='checkmark'></span>
                                            </label>
                                        </th>
                                        <th>Product UID</th>
                                        <th>Product Id</th>
                                        <th>Category</th>
                                        <th>Product Name</th>
                                        <th> Created by </th>
                                        <th> Created at </th>
                                        {isAdmin && <th style={{ textAlign: 'center' }}>Edit</th>}
                                        {isAdmin && <th style={{ textAlign: 'center' }}>Add RFID</th>}
                                        <th style={{ textAlign: 'center' }}>View QR</th>
                                        {user.role === 'seller' && <th style={{ textAlign: 'center' }}>Mark Sold</th>}
                                    </tr>
                                </thead>

                                <tbody>
                                    {!getTableData || getTableData.length === 0 ? (
                                        <tr>
                                            <td colSpan={11} align='center' style={{ textAlign: 'center' }}>
                                                {!loader && !isProductLoading && <>No Product Found</>}
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {getTableData.map((product) => {
                                                const isSelected = selectedItems.includes(product.productUId)

                                                return (
                                                    <tr key={product.productUId}>
                                                        <td>
                                                            <label style={{ padding: '0px 0px 15px 15px' }}>
                                                                <input type='checkbox' checked={isSelected} onChange={() => handleSelectItem(product.productUId)} />
                                                                <span className='checkmark'></span>
                                                            </label>
                                                        </td>
                                                        <td> {product.productUId}</td>
                                                        <td> {product.productID}</td>
                                                        <td> {product.category}</td>
                                                        <td> {product.productName || '-'} </td>
                                                        <td> {product.trail ? (product.trail.length > 0 ? product.trail[0].companyName : '-') : '-'}</td>
                                                        <td> {moment(product.createdAt, 'x').format('DD MMM YYYY hh:mm a')}</td>
                                                        {isAdmin && (
                                                            <td align='center'>
                                                                <div style={{ textAlign: 'center' }} onClick={() => props.history.push(`/product/${product.productUId}`)}>
                                                                    <i className='tiny material-icons tableIcon'>create</i>
                                                                </div>
                                                            </td>
                                                        )}
                                                        {isAdmin && (
                                                            <td align='center'>
                                                                <div style={{ textAlign: 'center' }} onClick={() => props.history.push(`/scan/${product.productUId}`)}>
                                                                    <i className='tiny material-icons tableIcon'>create</i>
                                                                </div>
                                                            </td>
                                                        )}
                                                        <td
                                                            style={{ cursor: product.qrCode === '' ? '' : 'pointer', textAlign: 'center' }}
                                                            onClick={() => {
                                                                if (product.qrCode !== '') {
                                                                    setProduct(product)
                                                                    toggle()
                                                                }
                                                            }}
                                                            align='center'
                                                        >
                                                            {product.qrCode === '' ? (
                                                                <span className='material-icons' style={{ color: 'gray', fontSize: '18px' }}>
                                                                    visibility
                                                                </span>
                                                            ) : (
                                                                <span className='material-icons' style={{ fontSize: '18px' }}>
                                                                    visibility
                                                                </span>
                                                            )}
                                                        </td>
                                                        {user.role === 'seller' && (
                                                            <td>
                                                                <div className='switch'>
                                                                    <label>
                                                                        <input
                                                                            type='checkbox'
                                                                            checked={product.isSold}
                                                                            onChange={() => {
                                                                                props.updateProductIsSoldStatus({ uid: product.productUId, id: product.productID, isSold: !product.isSold }, currentPage)
                                                                            }}
                                                                        />
                                                                        <span className='lever'></span>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {modal && <ProductsQrCode modal={modal} setModal={setModal} toggle={toggle} product={product} />}

                        <div className='col s6'>
                            <ReactPaginate
                                previousLabel={'<<'}
                                nextLabel={'>>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={Math.ceil(isSearching ? searchedTableData.length / PER_PAGE : newProducts.length / PER_PAGE)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        products: state.products.productList,
        totalProducts: state.products.totalProducts,
        user: state.auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProductList: (data) => fetchProductList(dispatch, data),
        updateProductIsSoldStatus: (product, currentPage) => updateProductSoldStatus(dispatch, product, currentPage + 1),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListProduct))
