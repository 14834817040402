import { LOADER } from "../actions/actions-types";

const initState = {
  loader: false,
};

const loaderReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADER.SHOW_LOADER:
      return {
        ...state,
        loader: true,
      };

    case LOADER.HIDE_LOADER:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};

export default loaderReducer;
