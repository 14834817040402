import React, { useState } from "react";
import { scanRfid } from "../../services/helper.service";
import { connect } from "react-redux";
import actionTypes from "../../store/actions/actions-types";

const SelectRFID = props => {
  const { selectedRfid, setSelectedRfid } = props;

  const handleScan = async () => {
    let resp = await scanRfid();
    // let resp = { success: true, rfid: "U-101" };
    if (resp && resp.success && resp.rfid) {
      setSelectedRfid(resp.rfid);
    } else {
      return props.showNotification({
        isError: true,
        msg: resp.msg
      });
    }
  };

  return (
    <div className="card z-depth-0">
      <span className="card-title"> {props.sectionTitle} </span>
      {!selectedRfid ? (
        <div className="input-field">
          <button className="btn z-depth-0" onClick={handleScan}>
            Scan RFID
          </button>
        </div>
      ) : (
          <>
          <div className="s-info">
            <strong> Tag scanned: {selectedRfid} </strong>
          </div>
          <button className="btn z-depth-0" onClick={() => setSelectedRfid(undefined)}> Cancel </button>
          </>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  showNotification: notification =>
    dispatch({ type: actionTypes.NOTIFICATION.SHOW_NOTFICATION, notification })
});

export default connect(null, mapDispatchToProps)(SelectRFID);
