import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import AddProduct from "./../products/AddProduct";
const Dashbaord = (props) => {
  // if (!props.user) return <Redirect to="/login" />;
  return (
    <div className="dashboard container">
      <AddProduct />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Dashbaord);
