import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import "./App.css";
import Navbar from "./components/layout/Navbar";
import Signup from "./components/auth/signup";
import Login from "./components/auth/login";
import Dashboard from "./components/dashboard/Dashbaord";
import NotFound from "./components/layout/Notfound";
import ProductDetails from "./components/products/ProductDetails";
import NotificationBar from "./components/utils/Notification";
import { connect, useSelector, useDispatch } from "react-redux";
import ListProduct from "./components/products/ListProduct";
import Upload from "./components/upload";
import Linking from "./components/linking/Linking";
import addUser from "./components/auth/addUser";
import verifyEmail from "./components/auth/verifyEmail";
import verifyOTP from "./components/auth/verifyOTP";
import Spinner from "./components/spinner/spinner";
// import Spinner from "./components/MainAdminContainer/MainAdminContainer";

const App = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const notification = useSelector((state) => state.notifications.notification);
  const loader = useSelector((state) => state.loader.loader);
  let userLocal = localStorage.getItem("user");
  if (typeof userLocal === "string") {
    userLocal = JSON.parse(userLocal);
  }

  useEffect(() => {
    dispatch({ type: "SET_USER", user: userLocal });
  }, []);

  // let user = props.user;
  const token = localStorage.getItem("token");
  // if (typeof user === "string") {
  //   user = JSON.parse(user);
  // }

  let role = userLocal ? userLocal.role : "";
  useEffect(() => {
    if (user) {
      role = userLocal.role;
    }
  }, [userLocal]);

  useEffect(() => {
    if (!token) {
      // window.location.replace("/login");
      // props.history.push("/login");
    }
  }, [token]);

  let mainContent = (
    <>
      <Route
        exact
        path="/"
        component={React.lazy(() => import("./components/auth/login"))}
      />

      <Route
        exact
        path="/login"
        component={React.lazy(() => import("./components/auth/login"))}
      />

      <Route
        path="/verify-email/:token"
        component={React.lazy(() => import("./components/auth/verifyEmail"))}
      />

      <Route
        path="/reset-password/:token"
        component={React.lazy(() => import("./components/auth/resetPassword"))}
      />

      <Route
        exact
        path="/verify-otp"
        component={React.lazy(() => import("./components/auth/verifyOTP"))}
      />
      {/* {(localStorage.getItem("user") !== null ||
        localStorage.getItem("user") !== undefined) && <Redirect to="/" />} */}
    </>
  );

  if (userLocal && token && role) {
    mainContent = (
      <>
        {role === "admin" ? (
          <Route
            path="/"
            component={React.lazy(() =>
              import("./components/MainAdminContainer/MainAdminContainer")
            )}
          />
        ) : (
          <Route
            path="/"
            component={React.lazy(() =>
              import("./components/MainContainer/MainContainer")
            )}
          />
        )}
      </>
    );
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <Route>
        <div className="App">
          <Navbar />

          {loader && <Spinner />}
          <Switch>
            {mainContent}
            {/* <Route exact path="/" component={Dashboard} />
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/product-details/:id?" component={ProductDetails} />
          <Route path="/product-details" component={ProductDetails} />
          <Route path="/product-list" component={ListProduct} />
          <Route path="/upload" component={Upload} />
          <Route path="/linking" component={Linking} />
          <Route path="/add-user" component={addUser} />
          <Route path="/verify-email/:token" component={verifyEmail} />
          <Route path="/verify-otp" component={verifyOTP} />
          <Route component={NotFound} /> */}
          </Switch>
        </div>
        <div>
          {notification && notification.msg && (
            <NotificationBar notification={notification} />
          )}

          {/* {true && (
            <NotificationBar notification={{ isError: true, msg: "qwe" }} />
          )} */}
        </div>
      </Route>
    </React.Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    notification: state.notifications.notification,
    // user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  let user = localStorage.getItem("user");
  if (typeof user === "string") {
    user = JSON.parse(user);
  }
  return {
    setUser: () => dispatch({ type: "SET_USER", user }),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(App);
export default withRouter(App);
