import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { addProduct } from '../../store/actions/product.actions'
import actionTypes from '../../store/actions/actions-types'
import axios from 'axios'
import ImageUploader from 'react-images-upload'
import { PRODUCT_DETAILS_URL, localServer } from '../../config/config'
import ShortUniqueId from 'short-unique-id'

export const AddProduct = (props) => {
    const [qrCode, setQrCode] = useState('null')

    let defaultProduct = {
        uid: '',
        id: '',
        name: '',
        category: '',
        description: '',
        image: '',
        isOkay: true,
        latitude: null,
        longitude: null,
    }

    let [product, setProduct] = useState(defaultProduct)
    let [image, setImage] = useState([])

    const handleChange = (e) => {
        setProduct({ ...product, [e.target.id]: e.target.value })
    }

    const handleChangeLatlong = (e) => {
        e.preventDefault()
        setProduct({ ...product, [e.target.id]: e.target.value })
    }

    // const handleSelectRfIDCheckbox = e => {
    //   setProduct({...product, isOkay: !e.target.checked})
    // }
    useEffect(() => {
        generateQrCode()
    }, [])
    const handleSubmit = async (e) => {
        product['qrCode'] = qrCode

        let { uid, name, id, description, longitude, category, latitude } = product
        if (!name || !id || !description || !image || !longitude || !latitude || !category) {
            props.showNotification({ isError: true, msg: 'Missing fields' })
            return
        }
        generateQrCode()
        const regex = new RegExp(/[+-]?([0-9]*[.])?[0-9]+/)

        if (!regex.test(longitude)) {
            props.showNotification({
                isError: true,
                msg: 'Longitude should not be string',
            })
            return
        }

        if (!regex.test(latitude)) {
            props.showNotification({
                isError: true,
                msg: 'Latitude should not be string',
            })
            return
        }
        props.addProduct2({ ...product, image })
        //setProduct(defaultProduct)
        setProduct({ ...defaultProduct, uid })
        setImage([])
    }

    const addToRFID = async () => {
        if (!product.uid) {
            props.showNotification({ isError: true, msg: 'Missing Product uid' })
            return
        }
        let viewProductUrl = `${PRODUCT_DETAILS_URL}/${product.uid}`
        let rfIdUrl = `${localServer.addRfid}/${viewProductUrl}`
        let resp = await axios.get(rfIdUrl)
        props.showNotification({ isError: false, msg: 'RFID tag added' })
    }

    const onDrop = async (_image) => {
        setImage(_image)
    }

    const generateQrCode = async () => {
        const randomCode = new ShortUniqueId({ length: 12 })
        const random_code = randomCode()
        setQrCode(random_code)
        // return random_code
    }

    return (
        <div className='card z-depth-0'>
            <div className='card-content'>
                <div className='row'>
                    <div className='col s12'>
                        <span className='card-title'>Product Details</span>
                    </div>
                    <form className='col s12'>
                        <div className='row'>
                            <div className='input-field col s6'>
                                <input id='uid' type='text' className='validate' value={product.uid} onChange={handleChange} />
                                <label htmlFor='uid'>RFID Label Id</label>
                            </div>
                            <div className='input-field col s6'>
                                <input id='id' type='text' className='validate' value={product.id} onChange={handleChange} />
                                <label htmlFor='id'>Product Id </label>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='input-field col s6'>
                                <input id='name' type='text' className='validate' value={product.name} onChange={handleChange} />
                                <label htmlFor='name'>Product Name</label>
                            </div>
                            <div className='input-field col s6'>
                                <input id='category' type='text' className='validate' value={product.category} onChange={handleChange} />
                                <label htmlFor='category'>Category</label>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='input-field col s6'>
                                <input id='description' type='text' className='validate' value={product.description} onChange={handleChange} />
                                <label htmlFor='description'>Description</label>
                            </div>

                            <div className='input-field col s6'>
                                <input id='latitude' type='text' className='validate' value={product.latitude} onChange={handleChangeLatlong} />
                                <label htmlFor='latitude'>Latitude</label>
                            </div>

                            <div className='input-field col s6'>
                                <input
                                    id='longitude'
                                    type='text'
                                    // step="10"
                                    className='validate'
                                    value={product.longitude}
                                    onChange={handleChangeLatlong}
                                />
                                <label htmlFor='longitude'>Longitude</label>
                            </div>

                            {/* <div className="input-field col s2">
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  value = {product.isOkay}
                  checked={!product.isOkay}
                  onClick = {handleSelectRfIDCheckbox}
                  />
                <span></span>
              </label>
              </div> */}
                        </div>

                        <ImageUploader withIcon={true} buttonText='Add Image' onChange={onDrop} imgExtension={['.jpg', '.gif', '.png', '.gif']} maxFileSize={5242880} withPreview={true} />

                        <button className='btn waves-effect waves-light' type='button' onClick={handleSubmit}>
                            Add
                            <i className='material-icons right'>send</i>
                        </button>

                        <button className='btn waves-effect waves-light' type='button' onClick={addToRFID}>
                            {' '}
                            Add To RFID
                            <i className='material-icons right'>cloud</i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        //addProduct1: async product => { dispatch(await addProduct(product))},
        addProduct2: (product) => addProduct(dispatch, product),
        showNotification: (notification) =>
            dispatch({
                type: actionTypes.NOTIFICATION.SHOW_NOTFICATION,
                notification,
            }),
    }
}

export default connect(null, mapDispatchToProps)(AddProduct)
