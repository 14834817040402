import { localServer } from "../config/config";
import axios from "axios";

export const promiseTimeout = function (ms, promise) {
  // Create a promise that rejects in <ms> milliseconds
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject("Timed out in " + ms + "ms.");
    }, ms);
  });

  // Returns a race between our timeout and the passed in promise
  return Promise.race([promise, timeout]);
};

export const getErrorMessage = (e) => {
  try {
    if (e.indexOf("UnauthorizedError") != -1)
      return "Unauthorize. Please logout and login again";
    else return e.response.data.message;
  } catch (error) {
    return "Something went wrong";
  }
};

export const scanRfid = async () => {
  try {
    let scanRfidUrl = `${localServer.readRfid}/uid`;
    let api = axios.get(scanRfidUrl);
    return promiseTimeout(3000, api)
      .then((resp) => {
        return { success: true, rfid: resp.data };
      })
      .catch((error) => {
        if (
          typeof error == "object" &&
          error.toString().indexOf("Network Error") != -1
        ) {
          return {
            success: false,
            msg: "Unable to scan. Make sure scanner is running",
          };
        } else {
          return {
            success: false,
            msg: "Unable to scan. Make sure scanner is running",
          };
        }
      });
  } catch (error) {
    return {
      success: false,
      msg: "Unable to scan. Something went wrong",
    };
  }
};
