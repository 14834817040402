import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'

const Navbar = (props) => {
    return (
        <nav className='nav-wrapper'>
            <div className='container'>
                <Link to='/' className='brand-logo'>
                    {' '}
                    <img src='logo.png' />{' '}
                </Link>
                {props.user ? signedInLinks(props) : signedOutLinks()}
            </div>
        </nav>
    )
}

function signedInLinks(props) {
    // const loggedInUser = props.user ? JSON.parse(props.user) : {};

    const isAdmin = props.user?.role === 'admin'
    const isSimpleRead = props.user?.role === 'simpleRead'
    const isFullRead = props.user?.role === 'fullRead'
    const isFullRead2FA = props.user?.role === 'fullRead2FA'

    return (
        <ul className='right'>
            {isAdmin && (
                <li>
                    {' '}
                    <NavLink to='/'> Add </NavLink>{' '}
                </li>
            )}
            <li>
                {' '}
                <NavLink to='/product-details'> Detail </NavLink>{' '}
            </li>
            <li>
                {' '}
                <NavLink to='/product-list'> List View </NavLink>{' '}
            </li>
            <li>
                {' '}
                <NavLink to='/scan-report'> Report</NavLink>{' '}
            </li>
            {isAdmin && (
                <li>
                    {' '}
                    <NavLink to='/upload'> Upload </NavLink>{' '}
                </li>
            )}
            {isAdmin && (
                <li>
                    {' '}
                    <NavLink to='/linking'> Link Product </NavLink>{' '}
                </li>
            )}
            {isAdmin && (
                <li>
                    {' '}
                    <NavLink to='/add-user'> Add User </NavLink>{' '}
                </li>
            )}
            {isAdmin && (
                <li>
                    {' '}
                    <NavLink to='/user-list'> User List </NavLink>{' '}
                </li>
            )}
            <li>
                {' '}
                <NavLink onClick={props.logout} to='/'>
                    {' '}
                    Logout{' '}
                </NavLink>{' '}
            </li>
        </ul>
    )
}

function signedOutLinks() {
    return (
        <ul className='right'>
            {/* <li>
        {" "}
        <NavLink to="/signup"> SignUp </NavLink>{" "}
      </li> */}
            <li>
                {' '}
                <NavLink to='/login'> Login </NavLink>{' '}
            </li>
        </ul>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch({ type: 'LOGOUT' })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
