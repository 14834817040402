import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NotificationBar(props) {
  toast.configure({
    // autoClose: 3000,
    draggable: false,
    pauseOnHover: true,
    //hideProgressBar: true
  });

  let msgConfig = {
    position: toast.POSITION.BOTTOM_RIGHT,
  };

  useEffect(() => {
    if (props.notification.isError) {
      toast.error(props.notification.msg, msgConfig);
    }
    if (!props.notification.isError) {
      toast.success(props.notification.msg, msgConfig);
    }
  }, [props.notification]);

  return (
    <div className="toast-ms">
      <ToastContainer />
    </div>
  );
}

export default NotificationBar;
